import React from 'react'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import ContentHero from '../components/ContentHero'
import Iframe from 'react-iframe'

const Newsletter = () => (
  <Layout>
    <Hero title="Newsletter" subtitle="Wir halten Sie auf dem Laufenden" />
    <div className="container">
      <ContentHero
        title="Alles Wissenswerte auf einen Klick"
        subtitle="Unser regelmäßiger Newsletter bietet Ihnen die Möglichkeit, sich ohne großen Aufwand über die aktuellen Themen bei colenio zu informieren."
      />

      <div className="columns">
        <div className="column is-half">
          <h2 className="title">Anmelden</h2>
          <Iframe
            url="https://subscribe.newsletter2go.com/?n2g=poiomzqb-e47lzhx3-g18&_ga=2.248053767.1689953033.1556285831-664617278.1555064257"
            display="flex"
            width="100%"
            height="650px"
            scrolling="no"
            frameborder="0"
            allowtransparency="true"
          />
        </div>
        <div className="column is-half">
          <h2 className="title">Abmelden</h2>
          <Iframe
            url="https://unsubscribe.newsletter2go.com/form.html?n2g=poiomzqb-e47lzhx3-g18&_ga=2.248053767.1689953033.1556285831-664617278.1555064257"
            display="flex"
            width="100%"
            height="300px"
            scrolling="no"
            frameborder="0"
            allowtransparency="true"
          />
        </div>
      </div>
    </div>
  </Layout>
)

export default Newsletter
